import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from 'rnd-helmet';
import {injectIntl} from "react-intl";
import SubMenu from "@Components/SubMenu";
import GetRouteByName from 'getRouteByName';
import ApplicationProcess from "@Components/ApplicationProcess";
import FormContext from "formContext";

function RequestConfirmation({intl, match}) {
    const homepageUrl = GetRouteByName('homepage').url;
    const {additionalData} = useContext(FormContext);
    const {privacySettings} = additionalData;

    const headerText = privacySettings ? privacySettings.header_text : ''
    const illustrationTitle = privacySettings ? privacySettings.illustration_title : ''
    const illustrationBody = privacySettings ? privacySettings.illustration_body : ''
    const illustrationLink = privacySettings ? privacySettings.illustration_link_text : ''

    return (
        <>
            <Helmet>
                <title>{intl.formatMessage({id: 'Menu.Request'})} | {intl.formatMessage({id: 'Breadcrumb.RequestConfirmation'})}</title>
                <header className={`header bg-variant-brand-primary header--s header--welcome`}/>
            </Helmet>
            <div className={'header bg-variant-brand-primary header--s header--welcome'}>
                <div className="header__wrapper wrapper">
                    <SubMenu skipBreadcrumbs={true}/>
                    <div className="header__content header__content--l content-block false">
                        <h1 className="content-block__title">
                            <span className="content-block__title-top">{headerText}</span>
                        </h1>
                    </div>
                </div>
            </div>
            <div className={`banner block--xs bg-variant-white`}>
                <div className="block__wrapper block__wrapper--stacked wrapper">
                    <div className="banner__wrapper">
                        <div className="banner__media media-block">
                            <img src="/myrandstad-app/assets/image/application-process/stars.svg" alt=""/>
                        </div>
                        <div className="banner__content content-block">
                            <h2 className="content-block__title">
                                {illustrationTitle}
                            </h2>
                            <p className="content-block__description">{illustrationBody}</p>
                            <a href={homepageUrl} className="button button--m">
                                {illustrationLink}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <ApplicationProcess settings={privacySettings}/>
        </>
    )
}

export default injectIntl(RequestConfirmation);
